import { BaseTextFieldProps, TextField, TextFieldProps } from '@mui/material'
import { RegisterOptions, useFormContext } from 'react-hook-form'

type Props = {
  name: string
  label: string
  active: boolean
  fieldId: string
  registerOptions: RegisterOptions
  inputProps?: BaseTextFieldProps['inputProps']
  muiTextFieldProps?: TextFieldProps
}

const NumberField = ({
  name,
  label,
  active,
  fieldId,
  registerOptions,
  inputProps,
  muiTextFieldProps,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <TextField
      id={`${name}=${label}`}
      name={name}
      label={label}
      variant="outlined"
      type="number"
      {...muiTextFieldProps}
      inputProps={{ ...inputProps, ...register(fieldId, registerOptions) }}
      disabled={!active}
      required={active}
      error={active && Boolean(errors[fieldId])}
    />
  )
}

export default NumberField
