export const isDayAndMonthString = (val: string | undefined) => {
  // not a perfect validator as does not account for e.g. 31/02 being invalid
  if (!val) return false
  if (val.length > 5) return false
  if (!val.includes('/')) return false
  const [day, month] = val.split('/')
  const dayInt = parseInt(day, 10)
  const monthInt = parseInt(month, 10)
  if (dayInt < 1 || dayInt > 31) return false
  if (monthInt < 1 || monthInt > 12) return false
  return true
}
