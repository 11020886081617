import { useState } from 'react'
import { Modal, Box, Typography } from '@mui/material'
import Button from '../buttons/Button'
import LinkButton from '../buttons/Button'

export const AlertMessage = () => {
  const [viewed, setViewed] = useState(() => {
    const saved = localStorage.getItem('viewed')
    const initialValue = saved && JSON.parse(saved)
    return initialValue || null
  })

  const onClose = () => {
    setViewed('true')
    localStorage.setItem('viewed', 'true')
  }

  const boxStyle = {
    position: 'absolute' as 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }

  return (
    <Modal
      open={!viewed}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Alert
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          This software is currently in Beta status. All values should be
          checked before project use. Please join the MS Team by clicking here:
        </Typography>
        <LinkButton
          style={{ marginTop: '20px' }}
          color="primary"
          onClick={() =>
            (window.location.href =
              'https://teams.microsoft.com/l/team/19%3a2c1fc3de9eda4cb1966a6f2272cc40d3%40thread.tacv2/conversations?groupId=a350a641-23e8-48cf-b294-3ce1b8bed824&tenantId=4ae48b41-0137-4599-8661-fc641fe77bea')
          }
        >
          Join Team
        </LinkButton>
        <Typography id="modal-modal-description-2" sx={{ mt: 2 }}>
          If you find a bug or if you have any suggestion, please open a new
          conversation in the Wind Whisperer channel inside the MS Team or send
          an email to{' '}
          <a href={'mailto:luca.amerio@arup.com'}>luca.amerio@arup.com</a>
        </Typography>
        <Button onClick={onClose} style={{ marginTop: '20px' }}>
          Close
        </Button>
      </Box>
    </Modal>
  )
}
