import { Stack, Typography } from '@mui/material'
import InfoPanel from '../layout/InfoPanel'

type Props = { title: React.ReactNode; children: React.ReactNode }

const FormSectionPanel = ({ children, title }: Props) => {
  return (
    <InfoPanel>
      <Typography variant="subtitle1" fontWeight={500} component="h2" mb={1}>
        {title}
      </Typography>
      <Stack gap={2}>{children}</Stack>
    </InfoPanel>
  )
}

export default FormSectionPanel
