export const tooltips = {
  dateRange: 'Dates range for the analysis',
  periodOfYear: (
    <>
      Samples selection based on the period of the year. Seasons are defined
      using standard equinoxes/solstices dates. Custom dates can be selected
      using <em>dd/mm</em> format.
    </>
  ),
  timeOfDay: (
    <>
      Samples selection based on the time of the day defined as <em>hh:mm</em>
    </>
  ),
  hourlyMean: (
    <>
      Average samples within +/- 30 minutes from the beginning of the hour. Wind
      direction is averaged using an <em>angular mean</em> formula; if wind
      directions varies significantly within an hour, the wind direction of the
      resulting averaged sample is marked as <em>variable</em>
    </>
  ),
  threshold:
    'Remove data where the value is larger or smaller than the selected threshold',
  ratio: (
    <>
      Remove data whose ratio with <strong>both</strong> the previous and the
      following sample is larger than the user defined value. Data with values
      less than the threshold are allways preserved.
    </>
  ),
  nMaxima: 'Remove the samples with the N largest values',
  removeVariableWindDirection:
    'Remove samples where wind direction is marked as `variable`. This is common in low speed samples.',
  removeThunderstorms:
    'Remove samples where the manual observation field indicates the presence of a non-synoptic event. Note that not all stations contain manual observation data.',
}
