import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { MainPaneTableWrapper } from '../../../ui/layout/MainPane'
import { personCellRenderer } from '../../../ui/gridCellRenderers/personCellRenderer'
import { formatDateCellValue } from '../../../utils/formattingUtils'
import type { Assessment } from '../../../types/Assessment'

type Props = {
  data: Assessment[]
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Assessment name',
    flex: 1,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    flex: 0.5,
    renderCell: personCellRenderer,
  },
  {
    field: 'dateCreated',
    headerName: 'Created on',
    flex: 0.5,
    valueFormatter: formatDateCellValue,
  },
]

const ProjectAssessments = ({ data }: Props) => {
  const navigate = useNavigate()
  return (
    <MainPaneTableWrapper>
      <DataGrid
        rows={data}
        columns={columns}
        rowHeight={75}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        disableVirtualization
        onRowClick={(params) => {
          navigate(`/assessment/${params.id}`)
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              There are currently no assessments to show. Fill in the ‘Start a
              new assessment’ form to get started.
            </Stack>
          ),
        }}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          height: 700,
          width: '100%',
        }}
      />
    </MainPaneTableWrapper>
  )
}

export default ProjectAssessments
