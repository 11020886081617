import Sidebar from '../../ui/layout/Sidebar'

const RecentAnalyses = () => {
  return (
    <Sidebar label="Recent Analyses" title="Recent Analyses">
      {null}
    </Sidebar>
  )
}

export default RecentAnalyses
