import { CircleLayer } from 'react-map-gl'

export const layerStyles: CircleLayer = {
  id: 'station',
  type: 'circle',
  paint: {
    'circle-radius': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      13,
      10,
    ],
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      '#7D4196',
      '#E66E23',
    ],
    'circle-stroke-color': '#FFFFFF',
    'circle-stroke-opacity': 0.8,
    'circle-stroke-width': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      7,
      3,
    ],
  },
}
