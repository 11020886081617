import { Box, Skeleton } from '@mui/material'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import ProjectAssessments from './Project/components/ProjectAssessments'
import ProjectSummary from './Project/components/ProjectSummary'
import JobNumbers from './Project/components/JobNumbers'
import NewAssessment from './Project/components/NewAssessment'
import { fetchProject } from '../lib/api/fetchProject'
import { Retry } from '../ui/alerts/Retry'

const Project = () => {
  const { projectId } = useParams()
  const { status, data, error, refetch } = useQuery(
    ['project', projectId],
    () => {
      if (!projectId) throw new Error('Project ID must be supplied')
      return fetchProject(projectId)
    }
  )

  return (
    <>
      <NewAssessment />
      <Box p={3} height="90%">
        {status === 'loading' && <Skeleton aria-label="loading" />}
        {status === 'success' && data && (
          <Box>
            <Box display="flex">
              <ProjectSummary data={data} />
              <JobNumbers jobNumbers={data.jobNumbers} />
            </Box>
            <ProjectAssessments data={data.assessments} />
          </Box>
        )}
        {status === 'error' && error && <Retry retry={refetch} />}
      </Box>
    </>
  )
}

export default Project
