import React from 'react'
import { Box, styled } from '@mui/material'

const Wrapper = styled('div')({
  height: '100%',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
})

export const MainPaneTableWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <Wrapper>
    <Box flexGrow={1}>{children}</Box>
  </Wrapper>
)
