import { Assessment } from '../../types/Assessment'

type NewAssessment = {
  name: string
  project: number
}

export const createAssessment = async (
  assessment: NewAssessment
): Promise<Assessment> => {
  const res = await fetchWithToken(`/api/assessment`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(assessment),
  })
  if (!res.ok) throw res
  return res.json()
}
