import { Assessment } from '../../types/Assessment'
import { Station } from '../../types/Station'

export const serverStationToNoaaStation = (
  serverStation: Required<Assessment>['station']
) => {
  const noaaStation: Station = {
    ID: serverStation.id,
    'STATION NAME': serverStation.name,
    'ELEV(M)': serverStation.elevation,
    LON: serverStation.longitude,
    LAT: serverStation.latitude,
    BEGIN: serverStation.begin,
    END: serverStation.end,
  }
  return noaaStation
}
export const noaaStationToServerStation = (noaaStation: Station) => {
  const serverStation: Required<Assessment>['station'] = {
    id: noaaStation['ID'],
    name: noaaStation['STATION NAME'],
    elevation: noaaStation['ELEV(M)'],
    longitude: noaaStation['LON'],
    latitude: noaaStation['LAT'],
    begin: noaaStation['BEGIN'],
    end: noaaStation['END'],
  }
  return serverStation
}
