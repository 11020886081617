import { Station } from '../../types/Station'
import GeoJSON from 'geojson'

const toGeoJSON = (stations: Station[]) => {
  const features: GeoJSON.Feature<GeoJSON.Geometry, Station>[] = stations.map(
    (station) => ({
      id: station.ID,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [station.LON, station.LAT],
      },
      properties: station,
    })
  )

  const featureCollection: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    Station
  > = {
    type: 'FeatureCollection',
    features: features,
  }
  return featureCollection
}

export const fetchStations = async (): Promise<
  GeoJSON.FeatureCollection<GeoJSON.Geometry, Station>
> => {
  const res = await fetchWithToken('/api/station')
  if (!res.ok) throw res
  const json = await res.json()
  return toGeoJSON(json)
}
