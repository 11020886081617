import { Box, Skeleton } from '@mui/material'
import { Retry } from '../../ui/alerts/Retry'
import Map from './SelectStation/components/StationMap'
import StationInformation from './SelectStation/components/StationInformation'
import { useStationsQuery } from '../../lib/queries/useStations'

const SelectStation = () => {
  const { status, data, error, refetch } = useStationsQuery()
  return (
    <>
      <StationInformation />
      <Box p={2} height="90%">
        {status === 'loading' && (
          <Skeleton
            aria-label="loading"
            variant="rectangular"
            width="100%"
            height="100%"
          />
        )}
        {status === 'success' && data && <Map data={data} />}
        {status === 'error' && error && <Retry retry={refetch} />}
      </Box>
    </>
  )
}

export default SelectStation
