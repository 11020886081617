import { Alert, Box, Skeleton } from '@mui/material'
import AssessmentSidebar from '../../ui/layout/AssessmentSidebar'
import { useAssessment } from '../Assessment'
import NoStationSelected from './CleanAndReview/components/NoStationSelected'
import CleanDataSettingsForm from './CleanAndReview/components/CleanDataSettingsForm'
import { useAppSelector } from '../../store/hooks'
import { selectCleanDataFormIsDirty } from '../../features/assessments/assessmentsSlice'
import VisualiseData from './CleanAndReview/components/VisualiseData'
import { useMutation } from 'react-query'
import type { CleanDataSettings } from '../../types/CleanDataSettings'
import { cleanWeatherData } from '../../lib/api/cleanWeatherData'
import { updateAssessment } from '../../lib/api/updateAssessment'

const CleanAndReview = () => {
  const assessment = useAssessment()
  const cleanDataFormIsDirty = useAppSelector(selectCleanDataFormIsDirty)

  const applySettingsMutation = useMutation(async (data: CleanDataSettings) => {
    if (!assessment.station) throw new Error('No station selected')
    return cleanWeatherData(assessment.id, assessment.station, data)
  })

  const saveSettingsMutation = useMutation(async (data: CleanDataSettings) => {
    if (!assessment.station) throw new Error('No station selected')
    return updateAssessment({ id: assessment.id, cleanDataSettings: data })
  })

  return (
    <>
      <AssessmentSidebar>
        {assessment.station && (
          <CleanDataSettingsForm
            assessment={assessment}
            applySettingsMutation={applySettingsMutation}
            saveSettingsMutation={saveSettingsMutation}
          />
        )}
      </AssessmentSidebar>
      {!assessment.station && <NoStationSelected />}
      <Box p={2} height="90%">
        {assessment.station && cleanDataFormIsDirty && (
          <Alert variant="outlined" severity="warning">
            You must click Apply changes to update plots
          </Alert>
        )}
        {applySettingsMutation.data && (
          <VisualiseData
            data={applySettingsMutation.data}
            assessment={assessment}
          />
        )}
        {applySettingsMutation.isLoading && (
          <Skeleton
            aria-label="loading"
            variant="rectangular"
            width="100%"
            height="100%"
          />
        )}
        {applySettingsMutation.status === 'error' && (
          <Alert variant="outlined" severity="error">
            Fetching data failed. Please try again.
          </Alert>
        )}
      </Box>
    </>
  )
}

export default CleanAndReview
