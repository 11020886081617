import { Typography, Box } from '@mui/material'
import { Person, PersonCardInteraction } from '@microsoft/mgt-react'
import { formatDate } from '../../../utils/formattingUtils'
import type { Project } from '../../../types/Project'

type Props = {
  data: Project
}

const ProjectSummary = ({ data: project }: Props) => {
  return (
    <Box
      sx={{
        width: '40%',
        maxHeight: '300px',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '32px',
          fontWeight: 700,
          color: 'rgba(var(--arc-grey-070),1)',
          marginBottom: '20px',
        }}
      >
        {project.name}
      </Typography>
      <Typography>
        <b>Created by: </b>
        <Person
          userId={project.createdBy}
          view={3}
          personCardInteraction={PersonCardInteraction.hover}
          onClick={(e) => e.stopPropagation()}
        />
      </Typography>
      <Typography>
        <b>Created on: </b>
        {formatDate(project.dateCreated)}
      </Typography>
      <Typography>
        <b>Description: </b>
        {project.description}
      </Typography>
    </Box>
  )
}

export default ProjectSummary
