import { RawWeatherDataObject } from '../../types/WeatherData'

export const formatWeatherData = (data: RawWeatherDataObject) => {
  const years = Object.keys(data.timeSeries)
  const xData: Date[] = []
  const yData: number[] = []
  const dirData: number[] = []
  const seaLevelPressureData: number[] = []
  const temperatureData: number[] = []
  const dewPointTempData: number[] = []
  const mw1_0Data: number[] = []
  let yMax = 0
  years.forEach((year) => {
    const yearData = data.timeSeries[year]
    yearData.index.forEach((dateTime, index) => {
      const x = new Date(dateTime * 100000) // data arrives in ms*10^-5 to minimise data transfer so needs multipled by 10^5 to convert to ms
      const y = yearData.data[index][1]
      const dir = yearData.data[index][0]
      const seaLevelPressure = yearData.data[index][2]
      const temperature = yearData.data[index][3]
      const dewPointTemp = yearData.data[index][4]
      const mw1_0 = yearData.data[index][5]
      xData.push(x)
      yData.push(y)
      dirData.push(dir)
      seaLevelPressureData.push(seaLevelPressure)
      temperatureData.push(temperature)
      dewPointTempData.push(dewPointTemp)
      mw1_0Data.push(mw1_0)
      if (y > yMax) {
        yMax = y
      }
    })
  })
  return {
    x: xData,
    y: yData,
    yMax,
    dirData,
    seaLevelPressureData,
    temperatureData,
    dewPointTempData,
    mw1_0Data,
    completeness: data.completeness,
    windRose: data.windRose,
  }
}
