import { Data, PlotType } from 'plotly.js'
import { WeatherData } from '../../types/WeatherData'
import { BoxPlotData } from 'plotly.js'

type Traces = {
  [key: string]: Partial<BoxPlotData>
}

const monthColors = [
  '49,54,149',
  '49,54,149',
  '107,162,203',
  '190,225,252',
  '254,204,125',
  '238,97,61',
  '165,0,38',
  '165,0,38',
  '238,97,61',
  '254,204,125',
  '190,225,252',
  '107,162,203',
]
const yearColor = 'rgb(31, 119, 180)'

export const groupByMonth = (data: WeatherData) => {
  let traces: Traces = {}
  data.x.forEach((date, index) => {
    let month = date.getMonth()
    let year = date.getFullYear()
    if (traces[month + '-' + year] === undefined) {
      traces[month + '-' + year] = {
        y: [],
        type: 'box',
        name: `${date.getFullYear()}-${date.getMonth() + 1}`,
        boxmean: true,
        boxpoints: false,
        marker: {
          color: `rgb(${monthColors[month]})`,
        },
      }
    }
    //@ts-ignore
    traces[month + '-' + year].y.push(data.y[index])
  })
  return Object.values(traces) as Data[]
}

export const groupByYear = (data: WeatherData) => {
  let traces: Traces = {}
  data.x.forEach((date, index) => {
    let year = date.getFullYear()
    if (traces[year] === undefined) {
      traces[year] = {
        y: [],
        type: 'box',
        name: date.getFullYear().toString(),
        boxmean: true,
        boxpoints: false,
        marker: {
          color: yearColor,
        },
      }
    }
    //@ts-ignore
    traces[year].y.push(data.y[index])
  })
  return Object.values(traces) as Data[]
}

const newSpeedGroups = {
  '0.0-2.0': { color: '#C3EAFE', name: '0.0 - 2.0 m/s' },
  '2.0-4.0': { color: '#6CC9F6', name: '2.0 - 4.0 m/s' },
  '4.0-6.0': { color: '#77DCCC', name: '4.0 - 6.0 m/s' },
  '6.0-8.0': { color: '#A3EB9F', name: '6.0 - 8.0 m/s' },
  '8.0-10.0': { color: '#85DB80', name: '8.0 - 10.0 m/s' },
  '10.0-12.0': { color: '#FFEA66', name: '10.0 - 12.0 m/s' },
  '12.0-14.0': { color: '#FFD10A', name: '12.0 - 14.0 m/s' },
  '14.0-16.0': { color: '#F59148', name: '14.0 - 16.0 m/s' },
  '16.0-inf': { color: '#F2454D', name: '16.0+ m/s' },
}

export const windRoseGrouping = (data: WeatherData) => {
  const newGroups = data.windRose.index.map((speedRange, index) => {
    return {
      r: data.windRose.data[index].map((v) => v * 100),
      theta: data.windRose.columns.map((dir) => `${dir}`),
      type: 'barpolar' as PlotType,
      name: newSpeedGroups[speedRange].name,
      marker: { color: newSpeedGroups[speedRange].color },
    }
  })
  return newGroups
}

export const completeness = (data: WeatherData) => ({
  z: data.completeness.data.map((year) => year.map((month) => month * 100)),
  x: data.completeness.columns,
  y: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  type: 'heatmap',
  colorscale: [
    [0, '#E61E28'],
    [0.6, '#F59148'],
    [0.7, '#FFDF4C'],
    [0.8, '#68C163'],
    [0.9, '#9DEDDA'],
    [1, '#D6FFF5'],
  ],
  colorbar: {
    ticksuffix: '%',
  },
  zmin: 0,
  zmax: 100,
})
