import saveAs from 'file-saver'
import { Options, parse } from 'json2csv'
import { useCallback } from 'react'

const useDownloadCsv = (parseOpts?: Options<unknown>) => {
  const downloadCsv = useCallback(
    (data, filename) => {
      const csv = parse(data, parseOpts)
      const file = new Blob([csv], { type: 'text/csv;charset=utf-8' })
      saveAs(file, filename || 'download.csv')
    },
    [parseOpts]
  )
  return downloadCsv
}

export default useDownloadCsv
