import { Project } from '../../types/Project'

type NewProject = {
  name: string
  description: string
  jobNumbers: string[]
}

export const createProject = async (project: NewProject): Promise<Project> => {
  const res = await fetchWithToken('/api/project', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(project),
  })
  if (!res.ok) throw res
  return res.json()
}
