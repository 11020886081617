import { ChipTypeMap } from '@mui/material/Chip'
import { GridValueFormatterParams } from '@mui/x-data-grid'

interface Status {
  name: string
  color: ChipTypeMap['props']['color']
}

interface StatusFormat {
  live: Status
  archived: Status
}

export const statusFormat: StatusFormat = {
  live: {
    name: 'Live',
    color: 'primary',
  },
  archived: {
    name: 'Archived',
    color: 'secondary',
  },
}

export const formatDate = (timestamp: string | number | Date) => {
  return new Date(timestamp).toLocaleDateString('en-GB')
}

export const formatDateCellValue = (params: GridValueFormatterParams) => {
  if (typeof params.value === 'string') {
    return formatDate(params.value)
  }
  return null
}
