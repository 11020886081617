import { TextField } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { isDayAndMonthString } from '../../lib/validators/isDayAndMonthString'

type Props = {
  name: string
  label: string
  active: boolean
  fieldId: string
}

const DayAndMonthField = ({ name, label, fieldId, active }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <TextField
      id={`${name}-${label}`}
      name={name}
      label={label}
      variant="outlined"
      inputProps={{
        maxLength: 5,
        ...register(fieldId, {
          validate: (val: string) => {
            if (!active) return true
            return isDayAndMonthString(val)
          },
        }),
      }}
      disabled={!active}
      required={active}
      error={active && Boolean(errors[fieldId])}
    />
  )
}

export default DayAndMonthField
