import { Login } from '@microsoft/mgt-react'
import LinkButton from '../buttons/LinkButton'

const Navbar = () => {
  return (
    <arc-navbar slot={'nav'} logo={'/logo.svg'} name="Wind Whisperer">
      <span slot="name">Wind Whisperer</span>
      <Login slot="user" />
      <LinkButton to="/" arcBtnProps={{ type: 'tab' }}>
        Home
      </LinkButton>
    </arc-navbar>
  )
}

export default Navbar
