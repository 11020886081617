import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import { UseFormRegisterReturn, useWatch } from 'react-hook-form'

type Props = {
  name: string
  tooltipContent?: React.ReactNode
  children?: React.ReactNode
  checkbox?: {
    label: string
    controlProps: UseFormRegisterReturn
  }
}

const FormFieldset = ({ name, checkbox, tooltipContent, children }: Props) => {
  const checked = useWatch({
    name: checkbox?.controlProps.name || '',
  })
  return (
    <fieldset
      style={{
        border: 'unset',
        padding: 'unset',
        margin: 'unset',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        mb={1}
      >
        <Typography>
          {!checkbox && <legend>{name}</legend>}
          {checkbox && (
            <FormControlLabel
              control={
                <Checkbox
                  {...checkbox.controlProps}
                  inputProps={{
                    'aria-label': checkbox.label,
                  }}
                  checked={checked}
                  color="default"
                />
              }
              label={<legend>{name}</legend>}
            />
          )}
        </Typography>
        {tooltipContent && (
          <Tooltip title={tooltipContent} placement="top" arrow>
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {children && (
        <Box display="flex" justifyContent="space-between" gap={1}>
          {children}
        </Box>
      )}
    </fieldset>
  )
}

export default FormFieldset
