import { Chip } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import { statusFormat } from '../../utils/formattingUtils'

export const projectStatusCellRenderer = (
  params: GridCellParams<'live' | 'archived'>
) => {
  if (params.value === 'live' || params.value === 'archived') {
    return (
      <Chip
        label={statusFormat[params.value].name}
        color={statusFormat[params.value].color}
        variant="outlined"
      />
    )
  }
  return null
}
