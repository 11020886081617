import { DateTime } from 'luxon'
import { Controller, Path, PathValue, UnpackNestedValue } from 'react-hook-form'
import type { Control, ControllerProps } from 'react-hook-form'
import { DatePicker, DatePickerProps } from '@mui/lab'
import { TextField } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'

type Props<T> = {
  name: Path<T>
  label: string
  required?: boolean
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  control: Control<T, any>
  rules?: ControllerProps['rules']
  muiDatePickerProps?: Partial<DatePickerProps>
  helperText?: string
}

const DateField = <T,>({
  name,
  label,
  defaultValue,
  control,
  rules,
  muiDatePickerProps,
  helperText,
  required = false,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <DatePicker
          label={label}
          components={{ OpenPickerIcon: ArrowDropDown }}
          disableHighlightToday
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={helperText}
              error={Boolean(fieldState.error)}
              required={required}
            />
          )}
          {...muiDatePickerProps}
          {...field}
          value={DateTime.fromMillis(field.value as number)}
          onChange={(e) => {
            if (e instanceof DateTime) field.onChange(e.toUnixInteger() * 1000)
          }}
        />
      )}
    />
  )
}

export default DateField
