import { Alert, Button } from '@mui/material'

type RetryAlertProps = {
  retry: () => void
  action?: string
  message?: string
}

export function Retry({ retry, action, message }: RetryAlertProps) {
  return (
    <Alert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={retry}>
          {action ?? 'RETRY'}
        </Button>
      }
    >
      {message ?? 'Something went wrong'}
    </Alert>
  )
}
