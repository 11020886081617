import { Stack, TextField, Box } from '@mui/material'
import { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Sidebar from '../../../ui/layout/Sidebar'
import Button from '../../../ui/buttons/Button'
import { Retry } from '../../../ui/alerts/Retry'

import { createAssessment } from '../../../lib/api/createAssessment'

import type { Project } from '../../../types/Project'
import { Assessment } from '../../../types/Assessment'
import { useRequiredParams } from '../../../lib/routerHooks/useRequiredParams'

type AssessmentFormInputs = {
  name: string
}

const submitEvent = new Event('submit', { bubbles: true, cancelable: true })

const NewAssessment = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<AssessmentFormInputs>()
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  const { projectId } = useRequiredParams(['projectId'])
  const projectIdAsNumber = parseInt(projectId, 10)
  const watchName = watch('name')
  const queryClient = useQueryClient()
  const createAssessmentMutation = useMutation(
    (data: AssessmentFormInputs) => {
      return createAssessment({
        name: data.name,
        project: projectIdAsNumber,
      })
    },
    {
      onSuccess: (newAssessment) => {
        queryClient.setQueryData<Project | undefined>(
          ['project', projectIdAsNumber],
          (project) =>
            project && {
              ...project,
              assessments: [...project.assessments, newAssessment],
            }
        )
        queryClient.setQueryData<Assessment>(
          ['assessment', newAssessment.id],
          newAssessment
        )
        navigate(`/assessment/${newAssessment.id}`)
      },
    }
  )
  const submitForm = () => formRef.current?.dispatchEvent(submitEvent)

  const resetForm = () => {
    createAssessmentMutation.reset()
    reset()
  }

  return (
    <Sidebar label="Start a new assessment">
      <form
        ref={formRef}
        onSubmit={handleSubmit((data) => createAssessmentMutation.mutate(data))}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Stack spacing={2} p={2}>
            <TextField
              id="assessment-name"
              name="assessmentName"
              label="Assessment name"
              variant="outlined"
              fullWidth
              required
              inputProps={{
                ...register('name', {
                  required: 'You must provide an assessment name',
                }),
              }}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          </Stack>
          <div>
            {createAssessmentMutation.isError && <Retry retry={submitForm} />}
            <Box m={2} display="flex" justifyContent="space-between" gap={2}>
              <Button onClick={resetForm} disabled={!watchName}>
                Discard
              </Button>
              <Button
                onClick={submitForm}
                color="primary"
                loading={createAssessmentMutation.isLoading}
                disabled={!watchName}
              >
                Save & Continue
              </Button>
            </Box>
          </div>
        </Box>
      </form>
    </Sidebar>
  )
}

export default NewAssessment
