import { Retry } from './../ui/alerts/Retry'
import { Box, Skeleton } from '@mui/material'

import { useQuery } from 'react-query'
import { Outlet } from 'react-router-dom'
import HomeActions from './Home/components/HomeActions'
import { fetchProjects } from '../lib/api/fetchProjects'
import ProjectsTable from './Home/components/ProjectsTable'

const Home = () => {
  const { status, data, error, refetch } = useQuery('projects', fetchProjects)

  return (
    <>
      <Box bgcolor="rgba(var(--arc-background-color),1)" p={1} pt={0}>
        <HomeActions />
      </Box>
      <Outlet />
      <Box p={2} height="90%">
        {status === 'loading' && <Skeleton aria-label="loading" />}
        {status === 'success' && data && <ProjectsTable data={data} />}
        {status === 'error' && error && <Retry retry={refetch} />}
      </Box>
    </>
  )
}

export default Home
