import { WeatherData } from '../../../../types/WeatherData'
import Plot from 'react-plotly.js'
import { windRoseGrouping } from '../../../../lib/dataTransformers/chartTransformers'
import { Box } from '@mui/material'

type Props = {
  data: WeatherData
}

const WindRose = ({ data }: Props) => {
  const windRose = windRoseGrouping(data)
  return (
    <Box width="100%" bgcolor={'rgb(250,250,250)'}>
      <Plot
        data={windRose}
        config={{
          displaylogo: false,
        }}
        layout={{
          legend: { font: { size: 16 } },
          title: 'Wind Rose',
          polar: {
            barmode: 'stack',
            bargap: 0,
            radialaxis: { ticksuffix: '%', angle: 45, dtick: 5 },
            angularaxis: { direction: 'clockwise' },
          },
          paper_bgcolor: 'rgb(250,250,250)',
          plot_bgcolor: 'rgb(250,250,250)',
          autosize: true,
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  )
}

export default WindRose
