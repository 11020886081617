import { MapboxMap } from 'react-map-gl'

export const deselectMapFeature = (
  featureId: string | number | undefined,
  map: MapboxMap,
  source: string
): void => {
  map.setFeatureState({ source, id: featureId }, { selected: false })
}
export const selectMapFeature = (
  featureId: string | number | undefined,
  map: MapboxMap,
  source: string
): void => {
  map.setFeatureState({ source, id: featureId }, { selected: true })
}

export const flyToFeature = (
  featureLon: number,
  featureLat: number,
  map: MapboxMap
) => {
  map.flyTo({ center: [featureLon, featureLat], duration: 1000 })
}
