import { Box } from '@mui/material'
import React from 'react'
import { useAssessment } from '../../routes/Assessment'
import Sidebar from './Sidebar'

type Props = { children?: React.ReactNode }

const AssessmentSidebar = (props: Props) => {
  const assessment = useAssessment()
  return (
    <Sidebar
      label={`${assessment.project.name} / ${assessment.name}`}
      title={`${assessment.project.name} / ${assessment.name}`}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        pl={1}
        pr={1}
      >
        {props.children}
      </Box>
    </Sidebar>
  )
}

export default AssessmentSidebar
