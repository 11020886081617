import { WeatherData } from '../../../../types/WeatherData'
import Plot from 'react-plotly.js'

type Props = {
  data: WeatherData
}

const TimeSeries = ({ data }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <Plot
        data={[
          {
            x: data.x,
            y: data.y,
            type: 'scatter',
            mode: 'lines',
          },
          {
            type: 'histogram',
            y: data.y,
            histnorm: 'probability',
            xaxis: 'x2',
            yaxis: 'y1',
            ybins: { start: 0, end: data.yMax, size: 1 },
            name: '',
            marker: { color: 'rgb(31, 119, 180)' },
          },
        ]}
        config={{
          responsive: true,
          displaylogo: false,
        }}
        layout={{
          showlegend: false,
          autosize: true,
          hovermode: 'x unified',
          title: 'Time History',
          xaxis: {
            title: 'Date',
            autorange: true,
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: '1m',
                  step: 'month',
                  stepmode: 'backward',
                },
                {
                  count: 6,
                  label: '6m',
                  step: 'month',
                  stepmode: 'backward',
                },
                { step: 'all' },
              ],
            },
            rangeslider: {},
            type: 'date',
            domain: [0, 0.725],
          },
          xaxis2: {
            title: 'Probability',
            domain: [0.775, 1],
          },
          yaxis: {
            title: 'Speed (m/s)',
          },
          paper_bgcolor: 'rgb(250,250,250)',
          plot_bgcolor: 'rgb(250,250,250)',
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

export default TimeSeries
