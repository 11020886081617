import { Divider, Stack, TextField, Box } from '@mui/material'
import { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Sidebar from '../../ui/layout/Sidebar'
import Button from '../../ui/buttons/Button'
import LinkButton from '../../ui/buttons/LinkButton'
import { Retry } from '../../ui/alerts/Retry'

import { createProject } from '../../lib/api/createProject'
import { isValidJobNumber } from '../../lib/validators/isValidJobnumber'

import type { Project } from '../../types/Project'

type ProjectFormInputs = {
  name: string
  jobNumber: string
  description: string
}

const submitEvent = new Event('submit', { bubbles: true, cancelable: true })

const NewProject = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectFormInputs>()
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const createProjectMutation = useMutation(
    (data: ProjectFormInputs) => {
      return createProject({
        name: data.name,
        jobNumbers: [data.jobNumber],
        description: data.description,
      })
    },
    {
      onSuccess: (newProject) => {
        queryClient.setQueryData('projects', (projects: Project[] = []) => [
          newProject,
          ...projects,
        ])
        navigate(`/project/${newProject.id}`)
      },
    }
  )
  const submitForm = () => formRef.current?.dispatchEvent(submitEvent)

  return (
    <Sidebar label="Start new project">
      <form
        ref={formRef}
        style={{ height: '100%' }}
        onSubmit={handleSubmit((data) => createProjectMutation.mutate(data))}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Stack spacing={2} p={2}>
            <TextField
              id="project-name"
              name="projectName"
              label="Project name"
              variant="outlined"
              fullWidth
              required
              inputProps={{
                ...register('name', {
                  required: 'You must provide a project name',
                }),
              }}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
            <TextField
              id="job-number"
              name="jobNumber"
              label="Job number"
              variant="outlined"
              fullWidth
              inputProps={{
                ...register('jobNumber', {
                  validate: (value) => {
                    return (
                      value.length === 0 ||
                      isValidJobNumber(value) ||
                      'Job number must be six numbers, then a dash, then two numbers (e.g. 233455-00)'
                    )
                  },
                }),
              }}
              error={Boolean(errors.jobNumber)}
              helperText={errors.jobNumber?.message}
            />
            <TextField
              id="project-description"
              name="projectDescription"
              label="Project description"
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              inputProps={{ ...register('description') }}
            />
          </Stack>
          <div>
            {createProjectMutation.isError && <Retry retry={submitForm} />}
            <Divider />
            <Box m={2} display="flex" justifyContent="space-between" gap={2}>
              <LinkButton to="..">Cancel</LinkButton>
              <Button
                onClick={submitForm}
                color="primary"
                loading={createProjectMutation.isLoading}
              >
                Save & Continue
              </Button>
            </Box>
          </div>
        </Box>
      </form>
    </Sidebar>
  )
}

export default NewProject
