import { WeatherData } from '../../../../types/WeatherData'
import Plot from 'react-plotly.js'
import {
  groupByYear,
  groupByMonth,
} from '../../../../lib/dataTransformers/chartTransformers'

type Props = {
  data: WeatherData
  by: 'year' | 'month'
}

const titles = {
  year: 'Data yearly summary',
  month: 'Data monthly summary',
}

const BoxPlot = ({ data, by }: Props) => {
  const boxplot = by === 'year' ? groupByYear(data) : groupByMonth(data)
  return (
    <Plot
      data={boxplot}
      config={{
        displaylogo: false,
        responsive: true,
      }}
      layout={{
        showlegend: false,
        autosize: true,
        hovermode: 'x unified',
        title: titles[by],

        yaxis: {
          title: 'Wind Speed (m/s)',
        },
        xaxis: {
          title: 'Date',
        },
        paper_bgcolor: 'rgb(250,250,250)',
        plot_bgcolor: 'rgb(250,250,250)',
      }}
      style={{ width: '100%', height: '100%' }}
      useResizeHandler={true}
    />
  )
}

export default BoxPlot
