import { Assessment } from '../../types/Assessment'

export const updateAssessment = async (
  assessment: Partial<Assessment>
): Promise<Assessment> => {
  const res = await fetchWithToken(`/api/assessment/${assessment.id}`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(assessment),
  })
  if (!res.ok) throw res
  return res.json()
}
