import {
  Person,
  PersonCardInteraction,
  PersonViewType,
} from '@microsoft/mgt-react'
import { GridCellParams } from '@mui/x-data-grid'

export const personCellRenderer = (params: GridCellParams<string>) => {
  if (typeof params.value === 'string') {
    const viewType: PersonViewType = 3
    return (
      <Person
        userId={params.value}
        view={viewType}
        personCardInteraction={PersonCardInteraction.hover}
        onClick={(e) => e.stopPropagation()}
      />
    )
  }
  return null
}
