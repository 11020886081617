import { WeatherData } from '../../../../types/WeatherData'
import Plot from 'react-plotly.js'
import { completeness } from '../../../../lib/dataTransformers/chartTransformers'
import { Box } from '@mui/material'

type Props = {
  data: WeatherData
}

const HeatMap = ({ data }: Props) => {
  const heatmap = completeness(data)
  return (
    <Box width="100%" bgcolor={'rgb(250,250,250)'}>
      <Plot
        data={[heatmap] as any}
        config={{
          displaylogo: false,
        }}
        layout={{
          title: 'Completeness',
          paper_bgcolor: 'rgb(250,250,250)',
          plot_bgcolor: 'rgb(250,250,250)',
          autosize: true,
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  )
}

export default HeatMap
