import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

const steps = [
  { path: 'select-station', label: 'Select a weather station' },
  { path: 'clean-and-review', label: 'Clean and review data' },
  { path: 'analyse-data', label: 'Analyse data' },
]

type Props = {
  currentPath:
    | 'select-station'
    | 'clean-and-review'
    | 'analyse'
    | string
    | undefined
}

const AssessmentStepper = ({ currentPath }: Props) => {
  return (
    <Stepper
      activeStep={steps.findIndex(({ path }) => path === currentPath)}
      alternativeLabel
    >
      {steps.map(({ label }) => {
        return (
          <Step
            key={label}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'rgba(var(--arc-color-primary),1)', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'rgba(var(--arc-color-primary),1)', // circle color (ACTIVE)
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default AssessmentStepper
