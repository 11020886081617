import { Assessment } from '../../types/Assessment'

export const fetchAssessment = async (
  assessmentId: number
): Promise<Assessment> => {
  const res = await fetchWithToken(`/api/assessment/${assessmentId}`)
  if (!res.ok) throw res
  const assessment: Assessment = await res.json()
  return assessment
}
