import { ArrowDropDown } from '@mui/icons-material'
import { TimePicker } from '@mui/lab'
import { TextField } from '@mui/material'
import { DateTime } from 'luxon'
import {
  Control,
  Controller,
  ControllerProps,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form'

type Props<T> = {
  name: Path<T>
  label: string
  control: Control<T, any>
  rules?: ControllerProps['rules']
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  active: boolean
}

const TimeField = <T,>({
  name,
  label,
  control,
  rules,
  active,
  defaultValue,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState }) => (
        <TimePicker
          label={label}
          views={['hours', 'minutes']}
          ampm={false}
          components={{ OpenPickerIcon: ArrowDropDown }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={active && Boolean(fieldState.error)}
              disabled={!active}
              required={active}
            />
          )}
          inputRef={ref}
          disabled={!active}
          {...field}
          value={
            typeof field.value === 'number'
              ? DateTime.fromMillis(field.value as number)
              : null
          }
          onChange={(e) => {
            if (e instanceof DateTime) field.onChange(e.toUnixInteger() * 1000)
          }}
        />
      )}
    />
  )
}

export default TimeField
