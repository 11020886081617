import { Box, Typography } from '@mui/material'
import LinkButton from '../../../../ui/buttons/LinkButton'

type Props = {}

const NoStationSelected = (props: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mt={2}
      p={4}
      gap={2}
      width="100%"
    >
      <Typography paragraph>
        You must select a station before you can clean and review the data.
        Please return to the "Select a weather station" step, select the station
        you wish to use from the map, and then click "Save and Continue".
      </Typography>
      <LinkButton to="../select-station">
        Return to select weather station
      </LinkButton>
    </Box>
  )
}

export default NoStationSelected
