import LinkButton from '../../../ui/buttons/LinkButton'
import AddIcon from '@mui/icons-material/Add'
type Props = {}

const HomeActions = (props: Props) => {
  return (
    <LinkButton
      to="new-project"
      arcBtnProps={{ color: 'primary' }}
      startIcon={<AddIcon sx={{ fontSize: 16 }} />}
    >
      Start new project
    </LinkButton>
  )
}

export default HomeActions
