import { Box } from '@mui/material'

import {
  Outlet,
  useMatch,
  useOutletContext,
  useResolvedPath,
} from 'react-router-dom'
import AssessmentStepper from './Assessment/components/AssessmentStepper'
import Sidebar from '../ui/layout/Sidebar'
import { Retry } from '../ui/alerts/Retry'
import type { Assessment as AssessmentType } from '../types/Assessment'
import type { SelectedStation } from '../types/Station'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  resetAssessmentStation,
  selectSavedStationIsNotStale,
  setAssessmentStation,
} from '../features/assessments/assessmentsSlice'
import { useAssessmentQuery } from '../lib/queries/useAssessment'
import Spinner from '../ui/loadingIndicators/Spinner'
import { serverStationToNoaaStation } from '../lib/dataTransformers/stationTransforms'
import { useRequiredParams } from '../lib/routerHooks/useRequiredParams'

export const useAssessment = () => useOutletContext<AssessmentType>()

const Assessment = () => {
  const { assessmentId } = useRequiredParams<'assessmentId'>(
    ['assessmentId'],
    'Assessment'
  )
  const assessment = useAssessmentQuery(parseInt(assessmentId, 10))
  const resolved = useResolvedPath('./:assessmentStep')
  const match = useMatch<'assessmentStep', string>({ path: resolved.pathname })
  const savedStationIsNotStale = useAppSelector(selectSavedStationIsNotStale)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      assessment.isSuccess &&
      assessment.data.station &&
      savedStationIsNotStale
    ) {
      const selectedStation: SelectedStation = {
        featureId: assessment.data?.station?.id,
        properties: serverStationToNoaaStation(assessment.data.station),
      }
      dispatch(setAssessmentStation(selectedStation))
    }
  }, [assessment, savedStationIsNotStale, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetAssessmentStation())
    }
  }, [dispatch])

  return (
    <>
      <Box mt={2} position="relative">
        <AssessmentStepper currentPath={match?.params.assessmentStep} />
        {assessment.status === 'error' && <Retry retry={assessment.refetch} />}
        {assessment.status === 'loading' && <Spinner />}
      </Box>
      {assessment.status !== 'success' && <Sidebar>{null}</Sidebar>}
      {assessment.status === 'success' && <Outlet context={assessment.data} />}
    </>
  )
}

export default Assessment
