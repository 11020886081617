import { WeatherData } from '../../../../types/WeatherData'
import BoxPlot from './BoxPlot'
import WindRose from './WindRose'
import TimeSeries from './TimeSeries'
import { Box } from '@mui/material'
import HeatMap from './HeatMap'
import React, { useMemo } from 'react'
import Button from '../../../../ui/buttons/Button'
import useDownloadCsv from '../../../../lib/utilityHooks/useDownloadCsv'
import { Assessment } from '../../../../types/Assessment'

type Props = {
  data: WeatherData
  assessment: Assessment
}

const VisualiseData = ({ data, assessment }: Props) => {
  const downloadCsv = useDownloadCsv()
  const timeSeries = useMemo(
    () =>
      data.x.map((timestamp, index) => {
        return {
          time: timestamp,
          windSpeed: data.y[index],
          windDir: data.dirData[index],
          seaLevelPressure: data.seaLevelPressureData[index],
          temperature: data.temperatureData[index],
          dewPointTemperature: data.dewPointTempData[index],
          MW1_0: data.mw1_0Data[index],
        }
      }),
    [data]
  )
  return (
    <>
      <Box height={'95%'} overflow="auto">
        <Box minHeight={500} display="flex" mb={3}>
          <TimeSeries data={data} />
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={'1fr 1fr'}
          justifyItems="center"
          gap={3}
        >
          <WindRose data={data} />
          <HeatMap data={data} />
          <BoxPlot data={data} by={'month'} />
          <BoxPlot data={data} by={'year'} />
        </Box>
      </Box>
      <Box m={2} display="flex" justifyContent="right">
        <Button
          onClick={() => {
            downloadCsv(
              timeSeries,
              `${assessment.project.name}-${assessment.name}-timeSeries.csv`
            )
          }}
        >
          Download Data
        </Button>
      </Box>
    </>
  )
}

export default React.memo(VisualiseData)
