import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { MainPaneTableWrapper } from '../../../ui/layout/MainPane'
import { projectNameCellRenderer } from '../../../ui/gridCellRenderers/projectNameCellRenderer'
import { projectStatusCellRenderer } from '../../../ui/gridCellRenderers/projectStatusCellRenderer'
import { formatDateCellValue } from '../../../utils/formattingUtils'
import type { Project } from '../../../types/Project'

type Props = {
  data: Project[]
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Project name',
    flex: 3,
    renderCell: projectNameCellRenderer,
  },
  {
    field: 'dateCreated',
    headerName: 'Created on',
    flex: 0.5,
    valueFormatter: formatDateCellValue,
  },
  {
    field: 'dateUpdated',
    headerName: 'Last updated',
    flex: 0.5,
    valueFormatter: formatDateCellValue,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: projectStatusCellRenderer,
  },
]

const ProjectsTable = ({ data }: Props) => {
  const navigate = useNavigate()
  return (
    <MainPaneTableWrapper>
      <DataGrid
        rows={data}
        columns={columns}
        rowHeight={75}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        disableVirtualization
        onRowClick={(params) => {
          navigate(`/project/${params.id}`)
        }}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </MainPaneTableWrapper>
  )
}

export default ProjectsTable
