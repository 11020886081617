import StationMetadata from './StationMetadata'
import { selectStation } from '../../../../features/assessments/assessmentsSlice'
import { useAppSelector } from '../../../../store/hooks'
import { Box } from '@mui/material'
import Button from '../../../../ui/buttons/Button'
import { useAssessment } from '../../../Assessment'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { updateAssessment } from '../../../../lib/api/updateAssessment'
import { Assessment } from '../../../../types/Assessment'
import { noaaStationToServerStation } from '../../../../lib/dataTransformers/stationTransforms'
import AssessmentSidebar from '../../../../ui/layout/AssessmentSidebar'

const StationInformation = () => {
  const selectedStation = useAppSelector(selectStation)
  const assessment = useAssessment()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const assessmentMutation = useMutation(
    (assessment: Partial<Assessment>) => updateAssessment(assessment),
    {
      onSuccess: (assessment) => {
        queryClient.setQueryData(['assessment', assessment.id], assessment)
        navigate('../clean-and-review')
      },
    }
  )

  return (
    <AssessmentSidebar>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <StationMetadata metadata={selectedStation?.properties} />
        <Box m={2} display="flex" justifyContent="space-between" gap={2}>
          <Button
            onClick={() => {
              navigate(`/project/${assessment.project.id}`)
            }}
          >
            Discard
          </Button>
          <Button
            onClick={() => {
              if (selectedStation) {
                assessmentMutation.mutate({
                  id: assessment.id,
                  station: noaaStationToServerStation(
                    selectedStation.properties
                  ),
                })
              }
            }}
            disabled={selectedStation === null ? true : undefined}
            color="primary"
            loading={assessmentMutation.isLoading}
          >
            Save & Continue
          </Button>
        </Box>
      </Box>
    </AssessmentSidebar>
  )
}

export default StationInformation
