import { Typography } from '@mui/material'
import React from 'react'

type Props = { children: React.ReactNode }

export const PanelHeader = ({ children }: Props) => (
  <Typography
    variant="h2"
    fontSize={16}
    fontWeight={700}
    color="rgba(var(--arc-grey-070),1)"
    mb={2}
  >
    {children}
  </Typography>
)
