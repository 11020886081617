import { Box, Stack, Typography } from '@mui/material'
import LinkButton from '../../../../ui/buttons/LinkButton'

const NoCleanDataSettings = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={4}
      gap={2}
      width="100%"
    >
      <Stack>
        <Typography paragraph>
          You must configure the settings you wish to use to clean the data,
          before you can start an analysis. Please return to the "Clean and
          review data" step, configure any filters you wish to apply, and then
          click "Save and Continue".
        </Typography>
        <Typography paragraph>
          Note that if you wish to analyse the raw data, you may return to the
          "Clean and review" step, and click "Save and continue" without setting
          any filters.
        </Typography>
      </Stack>
      <LinkButton to="../clean-and-review">
        Return to clean and review data
      </LinkButton>
    </Box>
  )
}

export default NoCleanDataSettings
