import { styled } from '@mui/material'

const InfoPanel = styled('div')({
  minHeight: '200px',
  backgroundColor: 'rgba(var(--arc-grey-000),1)',
  border: '1px solid rgba(var(--arc-grey-040),1)',
  padding: '16px',
})

export default InfoPanel
