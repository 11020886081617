import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import projectsReducer from '../features/projects/projectsSlice'
import assessmentsReducer from '../features/assessments/assessmentsSlice'

export const store = configureStore({
  reducer: {
    projects: projectsReducer,
    assessments: assessmentsReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
