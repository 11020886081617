import { Box, Typography } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'

export const projectNameCellRenderer = (params: GridCellParams) => (
  <Box ml="10px">
    <Typography
      sx={{
        fontWeight: 'bold',
      }}
    >
      {params.value}
    </Typography>
    {params.row.jobNumbers.length > 0 && (
      <Typography
        sx={{
          color: 'rgba(var(--arc-grey-060),1)',
          fontSize: '1em',
        }}
      >
        {`JN: ${params.row.jobNumbers[0].jobNumber}`}
      </Typography>
    )}
  </Box>
)
