import { Navigate } from 'react-router-dom'
import { useAssessment } from '../Assessment'

const RedirectToFirstIncompleteStep = () => {
  const assessment = useAssessment()

  if (assessment.cleanDataSettings)
    return <Navigate to="./analyse-data" replace />
  if (assessment.station) return <Navigate to="./clean-and-review" replace />
  return <Navigate to="./select-station" replace />
}

export default RedirectToFirstIncompleteStep
