import { Box, Typography } from '@mui/material'

type Props = {
  label: string
  value?: string | number
}

const InfoPanelItem = ({ label, value }: Props) => (
  <Box display="flex" justifyContent={'space-between'}>
    <Typography>{label}</Typography>
    <Typography>{value ?? '-'}</Typography>
  </Box>
)

export default InfoPanelItem
