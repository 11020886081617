import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'

export interface ProjectsState {
  newProjectForm: {
    name: string
    jobNumber: string
    description: string
  }
}

const initialState: ProjectsState = {
  newProjectForm: {
    name: '',
    jobNumber: '',
    description: '',
  },
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setNewProjectName: (state, action: PayloadAction<string>) => {
      state.newProjectForm.name = action.payload
    },
    setNewProjectJobNumber: (state, action: PayloadAction<string>) => {
      state.newProjectForm.jobNumber = action.payload
    },
    setNewProjectDescription: (state, action: PayloadAction<string>) => {
      state.newProjectForm.description = action.payload
    },
  },
})

export const { setNewProjectName } = projectsSlice.actions
export const selectNewProjectForm = (state: RootState) =>
  state.projects.newProjectForm

export default projectsSlice.reducer
