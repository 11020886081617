const Spinner = (style?: React.CSSProperties) => (
  <arc-spinner
    style={{
      position: 'absolute',
      top: '50%',
      left: 24,
      transform: 'translate(0,-50%)',
      fontSize: 32,
      '--stroke-color': 'rgb(var(--arc-color-primary))',
      ...style,
    }}
    role="loading"
  />
)

export default Spinner
