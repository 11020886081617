import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import type { SelectedStation } from '../../types/Station'
import type { CleanDataSettings } from '../../types/CleanDataSettings'

export interface AssessmentState {
  selectedStation: SelectedStation | null
  savedStationIsStale: boolean
  cleanDataSettings?: CleanDataSettings
  cleanDataFormIsDirty?: boolean
}

const initialState: AssessmentState = {
  selectedStation: null,
  savedStationIsStale: false,
}

export const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    setAssessmentStation: (state, action: PayloadAction<SelectedStation>) => {
      state.selectedStation = action.payload
      state.savedStationIsStale = true
    },
    resetAssessmentStation: (state) => {
      state.selectedStation = null
      state.savedStationIsStale = false
    },
    setCleanDataSettings: (state, action: PayloadAction<CleanDataSettings>) => {
      state.cleanDataSettings = action.payload
    },
    setCleanDataFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.cleanDataFormIsDirty = action.payload
    },
  },
})

export const {
  setAssessmentStation,
  resetAssessmentStation,
  setCleanDataSettings,
  setCleanDataFormIsDirty,
} = assessmentsSlice.actions
export const selectStation = (state: RootState) =>
  state.assessments.selectedStation
export const selectSavedStationIsNotStale = (state: RootState) =>
  !state.assessments.savedStationIsStale
export const selectCleanDataFormIsDirty = (state: RootState) =>
  state.assessments.cleanDataFormIsDirty

export default assessmentsSlice.reducer
