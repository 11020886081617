import { isPositiveInteger } from './isPositiveInteger'

export const isValidJobNumber = (value: string) => {
  const isNineCharsLong = value.length === 9
  const firstSixCharsAreNumbers = isPositiveInteger(value.slice(0, 6))
  const charsEightAndNineAreNumbers = isPositiveInteger(value.slice(7, 9))
  const charSevenIsHyphen = value.charAt(6) === '-'
  return (
    isNineCharsLong &&
    firstSixCharsAreNumbers &&
    charsEightAndNineAreNumbers &&
    charSevenIsHyphen
  )
}
