import { useRef } from 'react'
import Map, {
  Source,
  Layer,
  MapLayerMouseEvent,
  MapboxMap,
  MapboxGeoJSONFeature,
} from 'react-map-gl'
import GeoJSON from 'geojson'
import { setAssessmentStation } from '../../../../features/assessments/assessmentsSlice'
import type { Station } from '../../../../types/Station'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectMapFeature,
  deselectMapFeature,
  flyToFeature,
} from '../../../../lib/map/utils'
import { layerStyles } from './mapStyles'
import { useAssessment } from '../../../Assessment'

interface Props {
  data: GeoJSON.FeatureCollection
}

const StationMap = ({ data }: Props) => {
  const selectedFeatureId = useAppSelector(
    (state) => state.assessments.selectedStation?.featureId
  )
  const dispatch = useAppDispatch()
  const assessment = useAssessment()

  const mapRef = useRef<MapboxMap>()
  const source = 'station-data'

  const onSelect = (event: MapLayerMouseEvent) => {
    if (mapRef.current) {
      if (selectedFeatureId)
        deselectMapFeature(selectedFeatureId, mapRef.current, source)

      if (event.features) {
        const stationFeature = event.features[0]
        selectMapFeature(stationFeature.id, mapRef.current, source)

        const { properties, id } = stationFeature as MapboxGeoJSONFeature & {
          properties: Station
        }
        dispatch(setAssessmentStation({ properties, featureId: id }))
      }
    }
  }

  return (
    <Map
      initialViewState={{
        longitude: -0.137851,
        latitude: 51.521688,
        zoom: 6,
      }}
      style={{ width: '100%' }}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onClick={onSelect}
      onLoad={() => {
        if (assessment.station && mapRef.current) {
          selectMapFeature(assessment.station.id, mapRef.current, source)
          flyToFeature(
            assessment.station.longitude,
            assessment.station.latitude,
            mapRef.current
          )
        }
      }}
      interactiveLayerIds={[layerStyles.id]}
      //@ts-ignore
      ref={mapRef}
    >
      <Source id={source} type="geojson" data={data}>
        <Layer {...layerStyles} />
      </Source>
    </Map>
  )
}

export default StationMap
