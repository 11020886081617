import { Box, Stack, Typography } from '@mui/material'
import AssessmentSidebar from '../../ui/layout/AssessmentSidebar'
import { useAssessment } from '../Assessment'
import NoStationSelected from './CleanAndReview/components/NoStationSelected'
import Button from '../../ui/buttons/Button'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import NoCleanDataSettings from './AnaylseData/components/NoCleanDataSettings'

const CleanAndReview = () => {
  const assessment = useAssessment()
  const navigate = useNavigate()

  return (
    <>
      <AssessmentSidebar>
        <Stack overflow="hidden">
          <Box
            display="flex"
            justifyContent={'space-between'}
            alignItems="center"
            p={2}
          >
            <Typography
              variant="subtitle2"
              component="h1"
              color="rgb(var(--arc-grey-060))"
            >
              Analyse data
            </Typography>
          </Box>
        </Stack>
        <Box m={2} display="flex" justifyContent="space-between" gap={2}>
          <Button
            onClick={() => {
              navigate('../clean-and-review')
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {}}
            loading={false}
            disabled={true}
            color="primary"
          >
            Save & Finish
          </Button>
        </Box>
      </AssessmentSidebar>
      <Box p={2} height="90%">
        <Box display="flex" justifyContent="space-between" p={2} width="100%">
          {!assessment.station && <NoStationSelected />}
          {assessment.station && !assessment.cleanDataSettings && (
            <NoCleanDataSettings />
          )}
          {assessment.station && assessment.cleanDataSettings && (
            <>
              <Typography
                variant="body1"
                color="rgb(var(--arc-grey-060))"
                fontStyle={'italic'}
                paragraph
              >
                There is currently no analysis to show. Click 'Add analysis' to
                get started.
              </Typography>
              <Button
                startIcon={<AddIcon sx={{ fontSize: 16 }} />}
                color="info"
              >
                Add analysis
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default CleanAndReview
