import React from 'react'
import { Box } from '@mui/material'

export type ButtonProps = {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  children: React.ReactNode
  loading?: boolean
  arcBtnProps?: {
    type?: 'pill' | 'tab' | 'outlined'
    color?:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'warning'
      | 'info'
      | 'success'
  }
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const Button = ({
  startIcon,
  endIcon,
  children,
  loading,
  arcBtnProps,
  disabled,
  ...buttonProps
}: ButtonProps) => {
  return (
    <Box position="relative" height="100%">
      <arc-button
        data-testid="arc-button"
        role="button"
        type="pill"
        disabled={loading || disabled || undefined}
        {...arcBtnProps}
        {...buttonProps}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {startIcon && <span slot="prefix">{startIcon}</span>}
          <span>{children}</span>
          {endIcon && <span slot="suffix">{endIcon}</span>}
        </Box>
      </arc-button>
      {loading && (
        <arc-spinner
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 1,
          }}
          role="loading"
        />
      )}
    </Box>
  )
}
export default Button
