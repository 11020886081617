import type { Station } from '../../../../types/Station'
import { formatDate } from '../../../../utils/formattingUtils'
import { PanelHeader } from '../../../../ui/Typography'
import InfoPanel from '../../../../ui/layout/InfoPanel'
import InfoPanelItem from '../../../../ui/layout/InfoPanelItem'

type Props = {
  metadata?: Station
}

const StationMetadata = ({ metadata }: Props) => {
  return (
    <InfoPanel>
      <PanelHeader>Metadata</PanelHeader>

      <InfoPanelItem label="ID/Index:" value={metadata && metadata.ID} />
      <InfoPanelItem
        label="Station name:"
        value={metadata && metadata['STATION NAME']}
      />
      <InfoPanelItem
        label="Start date:"
        value={metadata && formatDate(metadata.BEGIN)}
      />
      <InfoPanelItem
        label="End date:"
        value={metadata && formatDate(metadata.END)}
      />
      <InfoPanelItem label="Latitude:" value={metadata && metadata.LAT} />
      <InfoPanelItem label="Longitude:" value={metadata && metadata.LON} />
      <InfoPanelItem
        label="Elevation:"
        value={metadata && metadata['ELEV(M)']}
      />
    </InfoPanel>
  )
}

export default StationMetadata
