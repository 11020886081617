import { useQuery } from 'react-query'
import { fetchStations } from '../api/fetchStations'

export const useStationsQuery = () =>
  useQuery('stations', fetchStations, {
    staleTime: 1000 * 60 * 20,
    cacheTime: 1000 * 60 * 60 * 12,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
