import ReactDOM from 'react-dom'
import App from './App'
import './auth/graphProvider'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

import { MsalProvider } from '@azure/msal-react'

import msalInstance from './auth/authConfig'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Provider } from 'react-redux'
import { store } from './store/store'

window.fetchWithToken = async (
  url: string,
  init?: RequestInit
): Promise<Response> => {
  if (url.startsWith('/api')) {
    const { accessToken } = await msalInstance.acquireTokenSilent({
      scopes: [`${process.env.REACT_APP_API_ID}/access_as_user`],
    })
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        'x-custom-authorization': `Bearer ${accessToken}`,
      },
    })
  }
  return fetch(url, init)
}

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
