import type { JobNumber } from '../../../types/JobNumber'
import { Typography, styled } from '@mui/material'

type Props = {
  jobNumbers: JobNumber[]
}

const JobNumbersWrapper = styled('div')({
  width: '350px',
  minHeight: '200px',
  backgroundColor: 'rgba(var(--arc-grey-000),1)',
  border: '1px solid rgba(var(--arc-grey-040),1)',
  paddingTop: '16px',
  paddingLeft: '24px',
  marginLeft: 'auto',
})

const JobNumbers = ({ jobNumbers }: Props) => {
  return (
    <JobNumbersWrapper>
      <Typography
        variant="h2"
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          color: 'rgba(var(--arc-grey-070),1)',
          marginBottom: '15px',
        }}
      >
        {'Job numbers (JN)'}
      </Typography>
      {jobNumbers.map((jobNumber) => (
        <Typography
          key={jobNumber.id}
          sx={{
            fontSize: '14px',
            marginBottom: '8px',
          }}
        >
          {jobNumber.jobNumber}
        </Typography>
      ))}
    </JobNumbersWrapper>
  )
}

export default JobNumbers
