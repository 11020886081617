import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
} from '@mui/material'
import {
  Control,
  Controller,
  ControllerProps,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form'

type Props<T> = {
  name: Path<T>
  label: string
  options: string[]
  control: Control<T, any>
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  rules?: ControllerProps['rules']
  active: boolean
  muiSelectProps?: SelectProps
}

const SelectField = <T,>({
  name,
  label,
  control,
  defaultValue,
  rules,
  options,
  active,
  muiSelectProps,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState }) => (
        <FormControl sx={{ minWidth: '120px' }}>
          <InputLabel
            error={active && Boolean(fieldState.error)}
            id={`${name}-${label}`}
          >
            {label}
          </InputLabel>
          <Select
            labelId={`${name}-${label}`}
            id={name}
            label={label}
            disabled={!active}
            required={active}
            multiple
            error={active && Boolean(fieldState.error)}
            {...muiSelectProps}
            inputRef={ref}
            {...field}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}

export default SelectField
