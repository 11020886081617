import { Link } from 'react-router-dom'
import Button, { ButtonProps } from './Button'

type Props = {
  to: string
} & ButtonProps

const LinkButton = ({ to, ...buttonProps }: Props) => {
  return (
    <Link to={to}>
      <Button {...buttonProps} />
    </Link>
  )
}

export default LinkButton
