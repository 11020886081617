import { Box } from '@mui/material'
import React from 'react'

type Props = {
  children: React.ReactNode
  title?: string
  label?: React.ReactNode
  style?: React.CSSProperties
}

const Sidebar = ({ children, label, title, style }: Props) => {
  return (
    <arc-sidebar style={{ transition: 'unset', ...style }} slot={'side'}>
      <div slot={'label'} title={title}>
        {label}
      </div>
      <Box overflow="hidden">{children}</Box>
    </arc-sidebar>
  )
}

export default Sidebar
